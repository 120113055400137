<template>
  <div class="games-header">
    <div class="header-logo">
      <img v-if="logoImageUrl" class="logo-image" :src="logoImageUrl" @click="showDefaultTable()" />
    </div>
    <div class="header-links">
      <div class="header-link promotion" @click="openPromotionPage()">世棒12強</div>
      <div class="header-link" @click="openLivePage()">{{ $t('Common.LivePlay') }}</div>
      <div class="header-link" @click="openScorePage()">{{ $t('GamesHeader.LiveScore') }}</div>
      <div class="header-link" @click="openResultPage()">{{ $t('GamesHeader.GameResult') }}</div>
      <div class="header-link" @click="openHistoryPage()">{{ $t('GamesHeader.BetList') }}</div>
      <div v-if="canShowDGLive" class="header-link" @click="openDGLivePage()">{{
        $t('Common.DGLive')
      }}</div>
      <div v-if="canShowSPLottery" class="header-link" @click="openSPLotteryPage()">{{
        $t('Common.SLottery')
      }}</div>
    </div>
    <div class="header-promotion">
      <img
        class="promotion-image"
        :src="promotionImageUrl"
        @click="showTable({ type: 3, cat: 101 })"
      />
    </div>
    <div class="header-menu">
      <div class="header-account">{{ userAccount }}</div>
      <div v-if="userBalance || userBalance === 0" class="header-balance">{{
        `$${toCurrency(userBalance)}`
      }}</div>
      <div v-if="canShowService" class="header-service">
        <img
          class="service-icon"
          src="@/assets/img/common/icon_header_service.svg"
          :title="$t('GamesHeader.ContactService')"
          @click="showServicePanel()"
        />
        <div v-show="userUnread > 0" class="service-unread" @click="showServicePanel()">{{
          userUnread
        }}</div>
      </div>
      <div class="header-user">
        <img
          class="user-icon"
          src="@/assets/img/common/icon_header_user.svg"
          :title="$t('GamesHeader.PersonalSetting')"
          @click="showUserPanel()"
        />
        <template v-if="isShowUserPanel">
          <div class="user-panel-bg" @click="hideUserPanel()" />
          <div class="user-panel">
            <div class="panel-buttons">
              <div class="panel-button" @click="showSettingPanel()">
                <img class="button-icon" src="@/assets/img/pc/setting.svg" />
                <div class="button-name">{{ $t('GamesBetInfo.Setting') }}</div>
              </div>
              <div class="panel-button" @click="showUserInfoPanel()">
                <img class="button-icon" src="@/assets/img/pc/member.svg" />
                <div class="button-name">{{ $t('Common.Profile') }}</div>
              </div>
              <div v-if="canShowPassword" class="panel-button" @click="showUserPasswordPanel()">
                <img class="button-icon" src="@/assets/img/pc/password.svg" />
                <div class="button-name">{{ $t('Common.ChangePassword') }}</div>
              </div>
              <div class="panel-button" @click="openLimitPage()">
                <img class="button-icon" src="@/assets/img/pc/member.svg" />
                <div class="button-name">{{ $t('Common.Limit') }}</div>
              </div>
            </div>
            <div v-if="hasPlugins" class="panel-plugins">
              <div v-if="userDGLiveOpen" class="panel-plugin">
                <span class="plugin-name">{{ $t('Common.DGLive') }}</span>
                <span v-if="DGLiveBalance || DGLiveBalance === 0" class="plugin-balance">{{
                  `$${toCurrency(DGLiveBalance)}`
                }}</span>
              </div>
              <div v-if="userSPLotteryOpen" class="panel-plugin">
                <span class="plugin-name">{{ $t('Common.SLottery') }}</span>
                <span v-if="SPLotteryBalance || SPLotteryBalance === 0" class="plugin-balance">{{
                  `$${toCurrency(SPLotteryBalance)}`
                }}</span>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div v-if="canShowLogout" class="header-logout">
        <img
          class="logout-icon"
          src="@/assets/img/common/logout.svg"
          :title="$t('GamesHeader.Logout')"
          @click="doLogout()"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
  import {
    apiGetDGLiveLogin,
    apiGetSPLotteryLogin,
    apiGetDGLiveBalance,
    apiGetSPLotteryBalance,
  } from '@/api/third';
  import { toCurrency } from '@/config/balance';
  import { openNewWindow } from '@/config/casino';

  export default {
    data() {
      return {
        promotionIndex: 1,
        promotionInteral: null,
        DGLiveBalance: '',
        SPLotteryBalance: '',
        isShowUserPanel: false,
      };
    },
    computed: {
      ...mapState(['i18nLocale', 'siteLabel', 'userBalance', 'userUnread']),
      ...mapGetters(['siteMainLogo', 'userAccount', 'userDGLiveOpen', 'userSPLotteryOpen']),
      logoImageUrl() {
        const { siteMainLogo } = this;
        return siteMainLogo;
      },
      promotionImageUrl() {
        const { promotionIndex } = this;
        return require(`@/assets/promotion/banner/banner${promotionIndex}.jpg`);
      },
      canShowDGLive() {
        const { userDGLiveOpen } = this;
        return userDGLiveOpen;
      },
      canShowSPLottery() {
        const { siteLabel } = this;
        return !siteLabel;
      },
      canShowService() {
        const { siteLabel } = this;
        return !siteLabel;
      },
      canShowLogout() {
        const { siteLabel } = this;
        return !siteLabel;
      },
      canShowPassword() {
        const { siteLabel } = this;
        return !siteLabel;
      },
      hasPlugins() {
        const { siteLabel, userDGLiveOpen, userSPLotteryOpen } = this;
        return !siteLabel && (userDGLiveOpen || userSPLotteryOpen);
      },
    },
    watch: {
      isShowUserPanel(show) {
        if (show && this.hasPlugins) {
          const { userDGLiveOpen, userSPLotteryOpen } = this;
          if (userDGLiveOpen) {
            apiGetDGLiveBalance().then((response) => {
              this.DGLiveBalance = response?.data?.Balance ?? '';
            });
          }
          if (userSPLotteryOpen) {
            apiGetSPLotteryBalance().then((response) => {
              this.SPLotteryBalance = response?.data?.Balance ?? '';
            });
          }
        }
      },
    },
    methods: {
      ...mapMutations(['showLoading', 'hideLoading']),
      ...mapActions(['logout']),
      ...mapActions('Game', ['showChampionTable', 'openPromotionPage', 'showTable']),
      toCurrency,
      showDefaultTable() {
        this.$store.dispatch('Game/updateDefaultGameDetail');
      },
      showUserPanel() {
        this.isShowUserPanel = true;
      },
      hideUserPanel() {
        this.isShowUserPanel = false;
      },
      showServicePanel() {
        this.$emit('openService');
        this.hideUserPanel();
      },
      showSettingPanel() {
        this.$emit('openSetting');
        this.hideUserPanel();
      },
      showUserInfoPanel() {
        this.$emit('openPersonal', 1);
        this.hideUserPanel();
      },
      showUserPasswordPanel() {
        this.$emit('openPersonal', 2);
        this.hideUserPanel();
      },
      openLivePage() {
        window.name = 'games';
        if (window.self !== window.top) {
          openNewWindow('Lives', 'lives');
        } else {
          const { href: url } = this.$router.resolve({
            path: 'Lives',
          });
          if (url) {
            window.open(url, 'lives');
          }
        }
      },
      openScorePage() {
        const { i18nLocale } = this;
        const locale = ['vi'].includes(i18nLocale) ? 'en' : i18nLocale;
        const url = `score/${locale}`;
        window.open(url, 'score');
      },
      openResultPage() {
        if (window.self !== window.top) {
          openNewWindow('GameResult', 'result');
        } else {
          const { href: url } = this.$router.resolve({
            path: 'GameResult',
          });
          if (url) {
            window.open(url, 'result');
          }
        }
      },
      openHistoryPage() {
        if (window.self !== window.top) {
          openNewWindow('HistoryRecord', 'history');
        } else {
          const { href: url } = this.$router.resolve({
            path: 'HistoryRecord',
          });
          if (url) {
            window.open(url, 'history');
          }
        }
      },
      openDGLivePage() {
        const newWindow = window.open();
        newWindow.opener = null;
        this.showLoading();
        apiGetDGLiveLogin()
          .then((res) => {
            const url = res.data?.GameUrl;
            if (url && newWindow) {
              newWindow.location.href = url;
            }
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            this.hideLoading();
          });
      },
      openSPLotteryPage() {
        if (this.userSPLotteryOpen) {
          this.showLoading();
          apiGetSPLotteryLogin()
            .then((response) => {
              const url = response.data?.GameUrl;
              if (url) {
                const a = btoa(url);
                window.open(`/SPLottery.html?${a}`, 'SPLottery');
              }
            })
            .catch((error) => {
              console.error(error);
            })
            .finally(() => {
              this.hideLoading();
            });
        } else {
          this.$message({
            type: 'info',
            message: this.$t('GamesHeader.Lottery1'),
            duration: 4000,
          });
        }
      },
      openLimitPage() {
        if (window.self !== window.top) {
          openNewWindow('Limits', 'news');
        } else {
          const { href: url } = this.$router.resolve({
            path: 'Limits',
          });
          if (url) window.open(url, 'limits');
        }
      },
      doLogout() {
        this.showLoading();
        this.logout()
          .then(() => {
            this.hideLoading();
          })
          .catch((error) => {
            console.error(error);
          });
      },
    },
    mounted() {
      this.promotionInteral = setInterval(() => {
        if (this.promotionIndex === 5) {
          this.promotionIndex = 1;
        } else {
          this.promotionIndex += 1;
        }
        /*
        if (this.promotionIndex === 4) {
          if (this.canShowDGLive) {
            this.promotionIndex = 1;
          } else {
            this.promotionIndex = 2;
          }
        } else {
          this.promotionIndex += 1;
        } */
      }, 5000);
    },
    beforeDestroy() {
      if (this.promotionInteral) {
        clearInterval(this.promotionInteral);
        this.promotionInteral = null;
      }
    },
  };
</script>

<style lang="scss">
  @import '@/assets/sass/theme/mixin.scss';

  @keyframes olympic {
    100% {
      transform: translate(-50%, -50%) scale(1.2);
    }
  }

  .games-header {
    @include base-background();

    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    height: 70px;
    z-index: 1;
    border-bottom: 1px solid #bbb;

    > .header-logo {
      flex: 0 1 320px;
      height: 100%;

      > .logo-image {
        width: 100%;
        height: 100%;
        object-fit: contain;
        cursor: pointer;
      }
    }

    > .header-links {
      flex: 1 0 fit-content;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      height: 100%;
      margin: 0 8px;
      padding-top: 16px;
      color: #eee;
      font-size: 15px;
      line-height: 15px;
      white-space: nowrap;

      > .header-link {
        margin: 0 5px;
        cursor: pointer;

        &:hover,
        &:active {
          color: #fff;
        }

        &.promotion {
          position: relative;
          margin-left: 16px;

          &:before {
            position: absolute;
            width: 52px;
            height: 52px;
            left: -28px;
            top: 40%;
            transform: translate(-50%, -50%);
            background-image: url(~@/assets/promotion/wbsc-logo.gif);
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            //animation: olympic 1.2s ease-in-out 1.2s infinite alternate;
            content: '';
          }
        }
      }
    }

    > .header-promotion {
      flex: 1 1 fit-content;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      height: 100%;
      margin: 0 8px;

      > .promotion-image {
        width: 100%;
        height: 100%;
        object-fit: contain;
        cursor: pointer;
      }
    }

    > .header-menu {
      flex: 0 0 fit-content;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      position: relative;
      height: 100%;
      margin: 0 8px;
      padding-top: 16px;
      color: #fff;
      font-size: 15px;
      line-height: 15px;

      > .header-account {
        margin: 0 5px;
      }

      > .header-balance {
        margin: 0 5px;
      }

      > .header-service {
        position: relative;
        margin: 0 5px;

        > .service-icon {
          width: 20px;
          cursor: pointer;
        }

        > .service-unread {
          position: absolute;
          left: calc(100% - 4px);
          top: -4px;
          padding: 3px 4px;
          border-radius: 8px;
          background-color: #c84141;
          color: #fff;
          font-size: 10px;
          line-height: 10px;
          transform: translateX(-50%);
          cursor: pointer;
        }
      }

      > .header-user {
        position: relative;
        margin: 0 5px;

        > .user-icon {
          width: 20px;
          cursor: pointer;
        }
      }

      > .header-logout {
        margin: 0 5px;

        > .logout-icon {
          width: 20px;
          cursor: pointer;
        }
      }
    }
  }

  .user-panel-bg {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.2);
  }

  .user-panel {
    display: flex;
    flex-flow: column nowrap;
    position: fixed;
    width: 150px;
    right: 4px;
    margin-top: 4px;
    padding: 4px;
    border-radius: 8px;
    box-shadow: 2px 4px 8px 0px rgba(0, 0, 0, 0.32);
    background-color: #fff;

    > .panel-buttons {
      display: flex;
      flex-flow: row wrap;
      width: 100%;

      > .panel-button {
        flex: 0 0 50%;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        padding-top: 4px;
        padding-bottom: 4px;
        border-radius: 8px;
        cursor: pointer;

        > .button-icon {
          height: 32px;
        }

        > .button-name {
          color: #444;
          font-size: 12px;
          line-height: 12px;
          text-align: center;
        }

        &:hover,
        &:active {
          background-color: #ddd;
        }
      }
    }

    > .panel-plugins {
      width: 100%;
      margin-top: 8px;

      > .panel-plugin {
        width: 100%;
        padding: 6px 8px 4px;
        border-radius: 8px;
        background-color: #444;
        color: #fff;
        font-size: 12px;
        line-height: 16px;
        text-align: center;

        > .plugin-name {
          &:after {
            padding-left: 4px;
            padding-right: 4px;
            content: ': ';
          }
        }

        > .plugin-balance {
        }
      }
    }
  }
</style>
